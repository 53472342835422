import React from 'react';

const IconBitwarden = () => (
  <svg width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M437.5 385.417C437.5 414.188 414.188 437.5 385.417 437.5H114.583C85.8229 437.5 62.5 414.188 62.5 385.417V114.583C62.5 85.8125 85.8229 62.5 114.583 62.5H385.417C414.188 62.5 437.5 85.8125 437.5 114.583V385.417Z" fill="url(#paint0_linear_3_37)" />
    <path opacity="0.05" d="M354.167 114.583C359.917 114.583 364.583 119.25 364.583 125V268.229C364.583 338.51 259.177 392.458 254.688 394.719C253.219 395.458 251.604 395.833 250 395.833C248.396 395.833 246.781 395.458 245.312 394.719C240.823 392.458 135.417 338.51 135.417 268.229V125C135.417 119.25 140.083 114.583 145.833 114.583H354.167ZM250 361.615C271.865 348.875 333.333 309.385 333.333 268.229V145.833H250V361.615ZM354.167 104.167H145.833C134.344 104.167 125 113.51 125 125V268.229C125 343.51 228.781 398.052 240.615 404.021C243.521 405.479 246.76 406.25 250 406.25C253.24 406.25 256.479 405.479 259.375 404.021C271.219 398.052 375 343.51 375 268.229V125C375 113.51 365.656 104.167 354.167 104.167ZM260.417 156.25H322.917V268.229C322.917 292.917 292.906 321.385 260.417 342.885V156.25Z" fill="black" />
    <path opacity="0.07" d="M250 401.042C247.573 401.042 245.135 400.458 242.969 399.375C238.365 397.052 130.208 341.635 130.208 268.229V125C130.208 116.385 137.219 109.375 145.833 109.375H354.167C362.781 109.375 369.792 116.385 369.792 125V268.229C369.792 341.635 261.635 397.052 257.031 399.365C254.865 400.458 252.427 401.042 250 401.042ZM145.833 119.792C142.958 119.792 140.625 122.125 140.625 125V268.229C140.625 335.292 243.281 387.865 247.656 390.073C249.104 390.792 250.896 390.802 252.354 390.073C256.719 387.865 359.375 335.292 359.375 268.229V125C359.375 122.125 357.042 119.792 354.167 119.792H145.833ZM244.792 370.677V140.625H338.542V268.229C338.542 311.375 278.458 351.052 252.625 366.115L244.792 370.677ZM255.208 151.042V352.396C279.385 337.333 328.125 302.698 328.125 268.229V151.042H255.208Z" fill="black" />
    <path d="M354.167 114.583H145.833C140.083 114.583 135.417 119.25 135.417 125V268.229C135.417 338.51 240.823 392.458 245.312 394.719C246.781 395.458 248.396 395.833 250 395.833C251.604 395.833 253.219 395.458 254.687 394.719C259.177 392.458 364.583 338.51 364.583 268.229V125C364.583 119.25 359.917 114.583 354.167 114.583ZM333.333 268.229C333.333 309.375 271.865 348.875 250 361.615V145.833H333.333V268.229Z" fill="white" />
    <defs>
      <linearGradient id="paint0_linear_3_37" x1="152.323" y1="-303.927" x2="290.458" y2="479.448" gradientUnits="userSpaceOnUse">
        <stop stop-color="#639BFF" />
        <stop offset="0.998" stop-color="#175DDC" />
      </linearGradient>
    </defs>
  </svg>

);

export default IconBitwarden;
