import React from 'react';

const IconNextcloud = () => (
  <svg width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_12)">
      <path d="M72.9167 322.917C32.7188 322.917 0 290.208 0 250C0 209.792 32.7188 177.083 72.9167 177.083C113.115 177.083 145.833 209.792 145.833 250C145.833 290.208 113.115 322.917 72.9167 322.917ZM72.9167 218.75C55.6875 218.75 41.6667 232.771 41.6667 250C41.6667 267.229 55.6875 281.25 72.9167 281.25C90.1458 281.25 104.167 267.229 104.167 250C104.167 232.771 90.1458 218.75 72.9167 218.75Z" fill="url(#paint0_linear_1_12)" />
      <path d="M427.083 322.917C386.885 322.917 354.167 290.208 354.167 250C354.167 209.792 386.885 177.083 427.083 177.083C467.281 177.083 500 209.792 500 250C500 290.208 467.281 322.917 427.083 322.917ZM427.083 218.75C409.854 218.75 395.833 232.771 395.833 250C395.833 267.229 409.854 281.25 427.083 281.25C444.312 281.25 458.333 267.229 458.333 250C458.333 232.771 444.312 218.75 427.083 218.75Z" fill="url(#paint1_linear_1_12)" />
      <path d="M250 364.583C186.823 364.583 135.417 313.177 135.417 250C135.417 186.823 186.823 135.417 250 135.417C313.177 135.417 364.583 186.823 364.583 250C364.583 313.177 313.177 364.583 250 364.583ZM250 177.083C209.802 177.083 177.083 209.792 177.083 250C177.083 290.208 209.802 322.917 250 322.917C290.198 322.917 322.917 290.208 322.917 250C322.917 209.792 290.198 177.083 250 177.083Z" fill="url(#paint2_linear_1_12)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_1_12" x1="66.4375" y1="175.969" x2="78.6563" y2="315.583" gradientUnits="userSpaceOnUse">
        <stop stop-color="#28AFEA" />
        <stop offset="1" stop-color="#0B88DA" />
      </linearGradient>
      <linearGradient id="paint1_linear_1_12" x1="420.729" y1="177.354" x2="433.437" y2="322.646" gradientUnits="userSpaceOnUse">
        <stop stop-color="#28AFEA" />
        <stop offset="1" stop-color="#0B88DA" />
      </linearGradient>
      <linearGradient id="paint2_linear_1_12" x1="240.01" y1="135.844" x2="259.99" y2="364.156" gradientUnits="userSpaceOnUse">
        <stop stop-color="#28AFEA" />
        <stop offset="1" stop-color="#0B88DA" />
      </linearGradient>
      <clipPath id="clip0_1_12">
        <rect width="500" height="500" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconNextcloud;