import React from 'react';

const IconGitHub = () => (
  <svg width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M227.083 43.75C131.25 54.1667 54.1667 131.25 43.75 225C33.3333 322.917 89.5833 410.417 175 443.75C181.25 445.833 187.5 441.667 187.5 433.333V400C187.5 400 179.167 402.083 168.75 402.083C139.583 402.083 127.083 377.083 125 362.5C122.917 354.167 118.75 347.917 112.5 341.667C106.25 339.583 104.167 339.583 104.167 337.5C104.167 333.333 110.417 333.333 112.5 333.333C125 333.333 135.417 347.917 139.583 354.167C150 370.833 162.5 375 168.75 375C177.083 375 183.333 372.917 187.5 370.833C189.583 356.25 195.833 341.667 208.333 333.333C160.417 322.917 125 295.833 125 250C125 227.083 135.417 204.167 150 187.5C147.917 183.333 145.833 172.917 145.833 158.333C145.833 150 145.833 139.583 150 131.25C150 127.083 154.167 125 156.25 125H158.333C168.75 127.083 189.583 133.333 208.333 152.083C220.833 147.917 235.417 145.833 250 145.833C264.583 145.833 279.167 147.917 291.667 152.083C310.417 133.333 333.333 127.083 343.75 125H345.833C350 125 352.083 127.083 354.167 131.25C354.167 139.583 354.167 150 354.167 158.333C354.167 175 352.083 183.333 350 187.5C364.583 204.167 375 225 375 250C375 295.833 339.583 322.917 291.667 333.333C304.167 343.75 312.5 362.5 312.5 381.25V435.417C312.5 441.667 318.75 447.917 327.083 445.833C404.167 414.583 458.333 339.583 458.333 252.083C458.333 127.083 352.083 29.1667 227.083 43.75Z" fill="white" />
  </svg>


);

export default IconGitHub;
