import React from 'react';

const IconLinkedin = () => (
  <svg width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M437.5 385.417C437.5 414.188 414.188 437.5 385.417 437.5H114.583C85.8229 437.5 62.5 414.188 62.5 385.417V114.583C62.5 85.8125 85.8229 62.5 114.583 62.5H385.417C414.188 62.5 437.5 85.8125 437.5 114.583V385.417Z" fill="#0078D4" />
    <path opacity="0.05" d="M312.5 385.417V280.219C312.5 262.625 303.969 252.115 289.667 252.115C281.177 252.115 274.937 256.896 271.135 266.323C270.958 266.99 270.708 269.708 270.812 277.927L270.833 385.417H197.917V187.5H270.833V198.552C281.479 191.208 294.531 187.5 309.771 187.5C357.135 187.5 385.406 219.719 385.406 273.688L385.417 385.417H312.5ZM114.583 385.417V187.5H150.594C129.729 187.5 114.583 172.167 114.583 151.031C114.583 129.917 129.979 114.583 151.187 114.583C172.146 114.583 187.073 129.49 187.5 150.823C187.5 172.115 172.094 187.5 150.885 187.5H187.5V385.417H114.583Z" fill="black" />
    <path opacity="0.07" d="M317.708 380.208V280.219C317.708 259.667 306.969 246.906 289.667 246.906C276.177 246.906 269.51 256.406 266.302 264.375C265.448 266.448 265.563 274.677 265.604 278.188L265.625 380.208H203.125V192.708H265.625V209.771C273.906 201.198 287.24 192.708 309.771 192.708C353.865 192.708 380.198 222.979 380.198 273.688L380.208 380.208H317.708ZM119.792 380.208V192.708H182.292V380.208H119.792ZM150.594 182.292C132.75 182.292 119.792 169.146 119.792 151.031C119.792 132.927 133 119.792 151.188 119.792C169.146 119.792 181.927 132.594 182.292 150.927C182.292 169.146 169.083 182.292 150.885 182.292H150.594Z" fill="black" />
    <path d="M125 197.917H177.083V375H125V197.917ZM150.885 177.083H150.594C135.052 177.083 125 165.5 125 151.031C125 136.25 135.365 125 151.188 125C167.031 125 176.792 136.25 177.083 151.031C177.083 165.49 167.031 177.083 150.885 177.083ZM375 375H322.917V280.219C322.917 257.323 310.156 241.698 289.667 241.698C274.031 241.698 265.573 252.24 261.469 262.427C259.969 266.073 260.417 276.156 260.417 281.25V375H208.333V197.917H260.417V225.167C267.927 213.542 279.688 197.917 309.771 197.917C347.042 197.917 374.99 221.354 374.99 273.688L375 375Z" fill="white" />
  </svg>

);

export default IconLinkedin;
